/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { Col, Layout, Row } from 'antd';
import propTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Scrollbars from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { UilAlignJustify } from '@iconscout/react-unicons';
import { FooterStyle, LayoutContainer, TopMenuSearch } from './Style';
import TopMenu from './TopMenu';
import HeaderInfo from './HeaderInfo';
import MenueItems from './MenueItems';
import config from '../config/config';
import { getTables } from '../services/tables';

const { theme } = require('../config/theme/themeVariables');

const { Header, Content, Sider } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        tables: [],
      };
      this.updateDimensions = this.updateDimensions.bind(this);
      this.getItem = this.getItem.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
      getTables({ kind: 'auxiliary' }).then((res) => {
        const links = [];
        res.map((item) => {
          const table = item.table ? item.table : '';
          links.push(
            this.getItem(
              <NavLink
                style={{ fontSize: 12 }}
                onClick={this.toggleCollapsed}
                to={`content/${item.kind}/${item.collectionName}/${table}`}
                className="menu-sidebar"
              >
                {item.displayName}
              </NavLink>,
              item.id,
            ),
          );
          return item;
        });

        this.setState({ tables: links });
      });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    getItem(label, key, icon, children, type) {
      return {
        key,
        icon,
        children,
        label,
        type,
      };
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide } = this.state;
      const { layoutMode, rtl, topMenu } = this.props;

      const left = !rtl ? 'left' : 'right';
      const toggleCollapsed = () => {
        this.setState({
          collapsed: !collapsed,
        });
      };

      const onShowHide = () => {
        this.setState({
          hide: !hide,
        });
      };

      const renderView = ({ style }) => {
        const customStyle = {
          marginRight: 'auto',
          [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div style={{ ...style, ...customStyle }} />;
      };

      const renderThumbVertical = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
          [left]: '2px',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };

      const renderThumbHorizontal = ({ style }) => {
        const { ChangeLayoutMode } = this.props;
        const thumbStyle = {
          borderRadius: 6,
          backgroundColor: ChangeLayoutMode ? '#ffffff16' : '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} />;
      };
      return (
        <LayoutContainer className={config.mainTemplate === 'lightColor' ? 'ligth-color' : 'dark-color'}>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <div className="ninjadash-header-content d-flex">
                <div className="ninjadash-header-content__left">
                  <div className="navbar-brand align-cener-v">
                    <UilAlignJustify onClick={toggleCollapsed} style={{ marginRight: 50, cursor: 'pointer' }} />

                    <Link className="ninjadash-logo top-menu" to="/dashboard">
                      <img style={{ maxHeight: 60 }} src={require(`../static/img/green-domus-logo-green.png`)} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="ninjadash-header-content__right d-flex">
                  <div className="ninjadash-navbar-menu d-flex align-center-v">
                    {topMenu && window.innerWidth > 767 ? <TopMenu /> : ''}
                  </div>
                  <div className="ninjadash-nav-actions">
                    <TopMenuSearch>
                      <div className="top-right-wrap d-flex">
                        <HeaderInfo />
                      </div>
                    </TopMenuSearch>
                  </div>
                </div>
                <div className="ninjadash-header-content__mobile">
                  <div className="ninjadash-mobile-action">
                    <Link className="btn-auth" onClick={onShowHide} to="#" />
                  </div>
                </div>
              </div>
            </Header>

            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={theme}>
                  <Sider
                    width={280}
                    style={{
                      margin: '63px 0 0 0',
                      padding: `${!rtl ? '20px 0px 55px 0' : '20px 0 55px 20px'}`,
                      overflowY: 'auto',
                      height: '100vh',
                      position: 'fixed',
                      left: 0,
                      zIndex: 988,
                    }}
                    collapsed={collapsed}
                    theme={layoutMode === 'lightMode' ? 'light' : 'dark'}
                  >
                    <Scrollbars
                      className="custom-scrollbar"
                      autoHide
                      autoHideTimeout={500}
                      autoHideDuration={200}
                      renderThumbHorizontal={renderThumbHorizontal}
                      renderThumbVertical={renderThumbVertical}
                      renderView={renderView}
                      renderTrackVertical={(props) => <div {...props} className="ninjadash-track-vertical" />}
                    >
                      <MenueItems topMenu={topMenu} toggleCollapsed={toggleCollapsed} tables={this.state.tables} />
                    </Scrollbars>
                  </Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout">
                <Content>
                  <WrappedComponent {...this.props} />
                  <FooterStyle className="admin-footer">
                    <Row>
                      <Col md={12} xs={24}>
                        <span className="admin-footer__copyright">
                          © 2023<Link to="#">Greendomus</Link>
                        </span>
                      </Col>
                      <Col md={12} xs={24}>
                        <div className="admin-footer__links">
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href = 'mailto:suporte@greendomus.info';
                              e.preventDefault();
                            }}
                          >
                            Suporte
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </FooterStyle>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </LayoutContainer>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      layoutMode: state.ChangeLayoutMode.mode,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      logo: state.auth.logo,
      isMaster: state.auth.isMaster,
    };
  };

  LayoutComponent.propTypes = {
    layoutMode: propTypes.string,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    logo: propTypes.string,
    isMaster: propTypes.bool,
  };

  return connect(mapStateToProps)(LayoutComponent);
};
export default ThemeLayout;
