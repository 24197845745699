/* eslint-disable react/prop-types */
import { Menu } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  UilEllipsisV,
  UilUser,
  UilUsersAlt,
  UilCreateDashboard,
  UilFileContract,
  UilBagAlt,
  UilTable,
  UilBuilding,
} from '@iconscout/react-unicons';
import propTypes from 'prop-types';

function MenuItems({ toggleCollapsed, tables }) {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const pathName = window.location.pathname;
  const pathArray = pathName?.split('/');
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath?.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = (keys) => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to="/" className="menu-sidebar">
        Dashboard
      </NavLink>,
      'dashboard',
      <UilCreateDashboard />,
    ),
    getItem(
      <NavLink onClick={toggleCollapsed} to="/user-profile" className="menu-sidebar">
        Seus dados
      </NavLink>,
      'user-profile',
      <UilUser />,
    ),
    getItem('Cadastros', 'registers', !topMenu && <UilBagAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to="/users" className="menu-sidebar">
          Usuários
        </NavLink>,
        'users',
        <UilUsersAlt style={{ marginLeft: 26 }} />,
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to="/companies" className="menu-sidebar">
          Empresas
        </NavLink>,
        'companies',
        <UilBuilding style={{ marginLeft: 26 }} />,
      ),

      getItem(
        <NavLink onClick={toggleCollapsed} to="/contracts" className="menu-sidebar">
          Contratos
        </NavLink>,
        'contracts',
        <UilFileContract style={{ marginLeft: 26 }} />,
      ),
    ]),
    getItem('Tabelas Básicas', 'table-auxiliary', !topMenu && <UilTable />, tables),
  ];
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode="inline"
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
